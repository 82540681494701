var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "position-relative" },
    [
      !_vm.hidden
        ? [
            _c(
              "vue-perfect-scrollbar",
              {
                staticClass: "scroll-area",
                attrs: {
                  settings: {
                    maxScrollbarLength: 60,
                    wheelPropagation: false,
                  },
                  tagname: "div",
                },
              },
              [
                _c(
                  "ul",
                  { staticClass: "ul-options m-0" },
                  _vm._l(_vm.menus, function (menu, index) {
                    return _c(
                      "li",
                      { key: index },
                      [
                        _vm.payBoxActionsEnum.TEF == menu.type
                          ? _c("pay-box-tef-actions", {
                              attrs: { "is-dark": "" },
                            })
                          : _c(
                              "b-button",
                              {
                                staticClass: "btn-pdv-operation",
                                class: {
                                  "disabled-opacity": !menu.hasPermission,
                                },
                                attrs: {
                                  id: menu.elId,
                                  variant: menu.disabled
                                    ? "dark"
                                    : "outline-dark",
                                  title: menu.text,
                                  disabled: menu.disabled,
                                },
                                on: {
                                  click: function () {
                                    return _vm.onVerifyUserPermission(
                                      menu.hasPermission,
                                      function () {
                                        return menu.clickAction(menu.type)
                                      }
                                    )
                                  },
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex flex-column align-items-center justify-content-center",
                                  },
                                  [
                                    _c("b-icon", {
                                      staticClass: "mb-1",
                                      attrs: { scale: "1.5", icon: menu.icon },
                                    }),
                                    _vm._v(" " + _vm._s(menu.text) + " "),
                                  ],
                                  1
                                ),
                              ]
                            ),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ]
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }